import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import style from './style';
import { region } from '../../../js/localizations/current-locale';
import DisclaimerWarningIcon from '../../../js/images/disclaimer-warning-icon.svg';
import { dynamicBrand } from '../../../js/utils/brand';

let messages;

import(`../../../js/utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const Wrapper = styled.div`
  &&{
    display: block;
    background-image: ${(props) => props.background};
    max-width: ${style.disclaimerMaxWidth};
    margin: auto;
    max-width: 37rem;
  }
`;

const WarningWrapper = styled.div`
  &&{
    margin-top: 0.5rem;
    display: flex;
    gap: 1rem;
    border-radius: 10px;
    background: #FFF;
    max-width: ${style.disclaimerMaxWidth};
    padding: 0.5rem;
  }
`;

const WarningText = styled.div`
  &&{
    font-family: var(--font-family);
    line-height: normal;
    font-size: 0.75rem;
    max-width: 29rem;
  }
`;

const WarningTitle = styled.p`
  &&{
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }
`;

const WarningIcon = styled.div`
`;

const WarningDisclaimer = ({
  intl,
}) => (
  <Wrapper>
    <WarningWrapper>
      <WarningIcon>
        <DisclaimerWarningIcon />
      </WarningIcon>
      <WarningText>
        <WarningTitle>{intl.formatMessage(messages.warningDisclaimerTitle)}</WarningTitle>
        {intl.formatMessage(messages.warningDisclaimerText)}
      </WarningText>
    </WarningWrapper>
  </Wrapper>
);

WarningDisclaimer.propTypes = {
  intl: PropTypes.shape().isRequired,
};

WarningDisclaimer.defaultProps = {
};

const WarningDisclaimerWithIntl = injectIntl(WarningDisclaimer);

export { WarningDisclaimerWithIntl as WarningDisclaimer };

export default WarningDisclaimerWithIntl;
